body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 0rem;
  }
}

.sidebar-sticky {
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
}

/*
 * Navbar
 */
.navbar{
  background-color: #2470dc !important;
  color: #fff !important;
}
.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(0, 0, 0, .25);
  color: #fff !important;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}


.navbar{
  position: fixed;
  width: 100%;
}
.main{
  margin-top: 50px;
}


.log-out-btn{
  border: #727272 solid 1px;
  border-radius: 10px;
  color: #fff !important;
}
.log-out-btn:hover{
  background-color: #f0f0f0;
}
.logout-btn:hover{
  background-color: #f0f0f0;
  color: #000 !important;
}

.image-delete-button{
  background-color: #E32816;
  color: #000;
  border-radius: 5px;
  margin: 3px;
}
.image-delete-button:hover{
  background-color: #000000;
  color: #E32816;
  border-radius: 5px;
  margin: 3px;
}
.nav-link{
  font-size: 1rem;
}
.nav-link:hover{
  background-color: #036db4;
  color: #fff !important;
}

.isActiveYes{
  color: #198754;
}
.isActiveNo{
  color: #DC3545;
}

.isActiveYes:hover{
  color: #00d371;
}
.isActiveNo:hover{
  color: #ff0019;
}


.card-text{
  font-size: 2rem;
}
.card{
  width: 100% !important;
  height: 180px;
}

.card:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}


.alert-message{
  margin-top: 50px !important;
}